import { Container } from './styles';

import { IoConstructOutline } from 'react-icons/io5';

function Home() {

  return (
    <Container>
      <IoConstructOutline />
      <h1>Under construction</h1>
    </Container>
  )
}

export default Home;